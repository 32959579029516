﻿import type { MseagFinanceChannelTypeEnum as ChannelType } from "@/gql/graphql";

interface WithConfigType<T extends ChannelType> {
  type: T;
}

interface CashConfig extends WithConfigType <ChannelType.Cash> {
  location: string;
}

interface TransferConfig extends WithConfigType<ChannelType.Transfer> {
  ownerName: string,
  accountNumber: string,
  country: string,
  bankName: string
}
interface InternationalTransferConfig extends Omit<TransferConfig, 'type'>, WithConfigType<ChannelType.International> {
  swiftCode: string;
  routingNumber: string;
}
interface UsaTransferConfig extends WithConfigType<ChannelType.UsaTransfer> {
  accountNumber: string;
  achRoutingNumber: string;
  ownerName: string;
}

interface ChannelConfigMap {
  [ChannelType.Cash]: CashConfig;
  [ChannelType.Transfer]: TransferConfig;
  [ChannelType.Rollover]: WithConfigType<ChannelType.Rollover>;
  [ChannelType.International]: InternationalTransferConfig;
  [ChannelType.UsaTransfer]: UsaTransferConfig
}
// should not error if config map is valid
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TestConfigMapIsExhaustive = ChannelConfigMap[ChannelType];
class ChannelService {
  typeConfig<T extends keyof ChannelConfigMap>(type: T, config: unknown): ChannelConfigMap[T] {
    return Object.assign({ type }, config ?? {}) as unknown as ChannelConfigMap[T];
  }

  configOutput(config: ChannelConfigMap[keyof ChannelConfigMap]) {
    const { type, ...withoutType } = config;
    return withoutType;
  }
}

export const channelService = new ChannelService();
