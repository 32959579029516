﻿<template>
  <h3 class="mt-4">
    Channel Details:
  </h3>
  <div class="detail-grid pl-2">
    <span>Channel:</span>
    <span>{{ channel.name }}</span>
    <span>Type:</span>
    <span>{{ typeName }}</span>
  </div>
  <v-card variant="tonal" color="secondary" class="mt-2 pa-2 pl-3">
    <div class="detail-grid">
      <template v-if="config.type ===$channelType.Cash">
        <span>Location</span>
        <span>{{ config.location }}</span>
      </template>
      <template v-if="config.type === $channelType.Transfer || config.type === $channelType.International || config.type === $channelType.UsaTransfer">
        <span>Owner Name</span>
        <span>{{ config.ownerName }}</span>
        <span>Account #</span>
        <span>{{ config.accountNumber }}</span>
      </template>
      <template v-if="config.type === $channelType.Transfer || config.type === $channelType.International">
        <span>Bank</span>
        <span>{{ config.bankName }}</span>
        <span>Country</span>
        <span>{{ config.country }}</span>
      </template>
      <template v-if="config.type === $channelType.International">
        <span>Swift Code</span>
        <span>{{ config.swiftCode }}</span>
        <span>Routing #</span>
        <span>{{ config.routingNumber }}</span>
      </template>
      <template v-if="config.type === $channelType.UsaTransfer">
        <span>ACH Routing #</span>
        <span>{{ config.achRoutingNumber }}</span>
      </template>
    </div>
  </v-card>
  <v-alert
    v-if="firstUse"
    class="mt-2"
    type="info"
    variant="tonal"
  >
    This is the first time this channel is being used, please confirm the details
  </v-alert>
</template>

<script setup lang="ts">
import { MseagFinanceChannelTypeEnum } from "@/gql/graphql";
import { channelService } from "@/services/channel-service";
import type { ChannelDetails } from "@/stores/cash-payment-store";
import { computed } from "vue";

const props = defineProps<{ channel: ChannelDetails, firstUse: boolean }>();
const config = computed(() => {
  return channelService.typeConfig(props.channel.channel_sub_type.type, props.channel.config);
});
const typeName = computed(() => {
  switch (props.channel.channel_sub_type.type) {
    case MseagFinanceChannelTypeEnum.Cash:
      return "Cash Pickup";
    case MseagFinanceChannelTypeEnum.Rollover:
      return "Rollover";
    case MseagFinanceChannelTypeEnum.Transfer:
      return "Bank Transfer";
    case MseagFinanceChannelTypeEnum.International:
      return "International Transfer";
    case MseagFinanceChannelTypeEnum.UsaTransfer:
      return "USA Transfer";
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unknown channel type: ${props.channel.channel_sub_type.type}`);
  }
});
</script>

<style scoped>
.detail-grid {
  display               : grid;
  grid-template-columns : auto 1fr;
  grid-column-gap: 1em;
}
</style>