﻿<template>
  {{ currency }}
</template>
<script setup lang="ts">
import { currencyNameMap } from "@/services/currencies";
import { computed } from "vue";

const props = defineProps<{ currency: string }>();
const currency = computed(() => {
  return currencyNameMap[props.currency] ?? props.currency;
});
</script>