﻿<template>
  {{ formattedNumber }}
</template>

<script setup lang="ts">
import { computed } from "vue";
const format = new Intl.NumberFormat();
const props = defineProps<{value: number}>();
const formattedNumber = computed(() => {
  return format.format(props.value);
});
</script>

<style scoped>

</style>