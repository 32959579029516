﻿export const currencyNameMap: Record<string, string> = {
  "THB": "BHT",
  "KHR": "CRL",
  "LAK": "KIP",
  "MMK": "KYT"
};

export function displayCurrencyCode(currency: undefined): undefined
export function displayCurrencyCode(currency: string): string
export function displayCurrencyCode(currency: string | undefined): string | undefined
export function displayCurrencyCode(currency: string | undefined) {
  if (currency === undefined) return undefined;
  return currencyNameMap[currency] ?? currency;
}

export const currencies: { name: string, code: string }[] = [
  { name: "United Arab Emirates dirham", code: "AED" },
  { name: "Afghan afghani", code: "AFN" },
  { name: "Albanian lek", code: "ALL" },
  { name: "Armenian dram", code: "AMD" },
  { name: "Netherlands Antillean guilder", code: "ANG" },
  { name: "Angolan kwanza", code: "AOA" },
  { name: "Argentine peso", code: "ARS" },
  { name: "Australian dollar", code: "AUD" },
  { name: "Aruban florin", code: "AWG" },
  { name: "Azerbaijani manat", code: "AZN" },
  { name: "Bosnia and Herzegovina convertible mark", code: "BAM" },
  { name: "Barbadian dollar", code: "BBD" },
  { name: "Bangladeshi taka", code: "BDT" },
  { name: "Bulgarian lev", code: "BGN" },
  { name: "Bahraini dinar", code: "BHD" },
  { name: "Burundian franc", code: "BIF" },
  { name: "Bermudian dollar", code: "BMD" },
  { name: "Brunei dollar", code: "BND" },
  { name: "Bolivian boliviano", code: "BOB" },
  { name: "Brazilian real", code: "BRL" },
  { name: "Bahamian dollar", code: "BSD" },
  { name: "Bhutanese ngultrum", code: "BTN" },
  { name: "Botswana pula", code: "BWP" },
  { name: "Belarusian ruble", code: "BYN" },
  { name: "Belize dollar", code: "BZD" },
  { name: "Canadian dollar", code: "CAD" },
  { name: "Congolese franc", code: "CDF" },
  { name: "Swiss franc", code: "CHF" },
  { name: "Chilean peso", code: "CLP" },
  { name: "Renminbi", code: "CNY" },
  { name: "Colombian peso", code: "COP" },
  { name: "Costa Rican colón", code: "CRC" },
  { name: "Cuban peso", code: "CUP" },
  { name: "Cape Verdean escudo", code: "CVE" },
  { name: "Czech koruna", code: "CZK" },
  { name: "Djiboutian franc", code: "DJF" },
  { name: "Danish krone", code: "DKK" },
  { name: "Dominican peso", code: "DOP" },
  { name: "Algerian dinar", code: "DZD" },
  { name: "Egyptian pound", code: "EGP" },
  { name: "Eritrean nakfa", code: "ERN" },
  { name: "Ethiopian birr", code: "ETB" },
  { name: "Euro", code: "EUR" },
  { name: "Fijian dollar", code: "FJD" },
  { name: "Falkland Islands pound", code: "FKP" },
  { name: "Sterling", code: "GBP" },
  { name: "Georgian lari", code: "GEL" },
  { name: "Ghanaian cedi", code: "GHS" },
  { name: "Gibraltar pound", code: "GIP" },
  { name: "Gambian dalasi", code: "GMD" },
  { name: "Guinean franc", code: "GNF" },
  { name: "Guatemalan quetzal", code: "GTQ" },
  { name: "Guyanese dollar", code: "GYD" },
  { name: "Hong Kong dollar", code: "HKD" },
  { name: "Honduran lempira", code: "HNL" },
  { name: "Haitian gourde", code: "HTG" },
  { name: "Hungarian forint", code: "HUF" },
  { name: "Indonesian rupiah", code: "IDR" },
  { name: "Israeli new shekel", code: "ILS" },
  { name: "Indian rupee", code: "INR" },
  { name: "Iraqi dinar", code: "IQD" },
  { name: "Iranian rial", code: "IRR" },
  { name: "Icelandic króna", code: "ISK" },
  { name: "Jamaican dollar", code: "JMD" },
  { name: "Jordanian dinar", code: "JOD" },
  { name: "Japanese yen", code: "JPY" },
  { name: "Kenyan shilling", code: "KES" },
  { name: "Kyrgyz som", code: "KGS" },
  { name: "Cambodian riel (KHR)", code: "KHR" },
  { name: "Comorian franc", code: "KMF" },
  { name: "North Korean won", code: "KPW" },
  { name: "South Korean won", code: "KRW" },
  { name: "Kuwaiti dinar", code: "KWD" },
  { name: "Cayman Islands dollar", code: "KYD" },
  { name: "Kazakhstani tenge", code: "KZT" },
  { name: "Lao kip (LAK)", code: "LAK" },
  { name: "Lebanese pound", code: "LBP" },
  { name: "Sri Lankan rupee", code: "LKR" },
  { name: "Liberian dollar", code: "LRD" },
  { name: "Lesotho loti", code: "LSL" },
  { name: "Libyan dinar", code: "LYD" },
  { name: "Moroccan dirham", code: "MAD" },
  { name: "Moldovan leu", code: "MDL" },
  { name: "Malagasy ariary", code: "MGA" },
  { name: "Macedonian denar", code: "MKD" },
  { name: "Burmese kyat (MMK)", code: "MMK" },
  { name: "Mongolian tögrög", code: "MNT" },
  { name: "Macanese pataca", code: "MOP" },
  { name: "Mauritanian ouguiya", code: "MRU" },
  { name: "Mauritian rupee", code: "MUR" },
  { name: "Maldivian rufiyaa", code: "MVR" },
  { name: "Malawian kwacha", code: "MWK" },
  { name: "Mexican peso", code: "MXN" },
  { name: "Malaysian ringgit", code: "MYR" },
  { name: "Mozambican metical", code: "MZN" },
  { name: "Namibian dollar", code: "NAD" },
  { name: "Nigerian naira", code: "NGN" },
  { name: "Nicaraguan córdoba", code: "NIO" },
  { name: "Norwegian krone", code: "NOK" },
  { name: "Nepalese rupee", code: "NPR" },
  { name: "New Zealand dollar", code: "NZD" },
  { name: "Omani rial", code: "OMR" },
  { name: "Panamanian balboa", code: "PAB" },
  { name: "Peruvian sol", code: "PEN" },
  { name: "Papua New Guinean kina", code: "PGK" },
  { name: "Philippine peso", code: "PHP" },
  { name: "Pakistani rupee", code: "PKR" },
  { name: "Polish złoty", code: "PLN" },
  { name: "Paraguayan guaraní", code: "PYG" },
  { name: "Qatari riyal", code: "QAR" },
  { name: "Romanian leu", code: "RON" },
  { name: "Serbian dinar", code: "RSD" },
  { name: "Russian ruble", code: "RUB" },
  { name: "Rwandan franc", code: "RWF" },
  { name: "Saudi riyal", code: "SAR" },
  { name: "Solomon Islands dollar", code: "SBD" },
  { name: "Seychellois rupee", code: "SCR" },
  { name: "Sudanese pound", code: "SDG" },
  { name: "Swedish krona", code: "SEK" },
  { name: "Singapore dollar", code: "SGD" },
  { name: "Saint Helena pound", code: "SHP" },
  { name: "Sierra Leonean leone", code: "SLE" },
  { name: "Somali shilling", code: "SOS" },
  { name: "Surinamese dollar", code: "SRD" },
  { name: "South Sudanese pound", code: "SSP" },
  { name: "São Tomé and Príncipe dobra", code: "STN" },
  { name: "Syrian pound", code: "SYP" },
  { name: "Swazi lilangeni", code: "SZL" },
  { name: "Thai baht (THB)", code: "THB" },
  { name: "Tajikistani somoni", code: "TJS" },
  { name: "Turkmenistani manat", code: "TMT" },
  { name: "Tunisian dinar", code: "TND" },
  { name: "Tongan paʻanga", code: "TOP" },
  { name: "Turkish lira", code: "TRY" },
  { name: "Trinidad and Tobago dollar", code: "TTD" },
  { name: "New Taiwan dollar", code: "TWD" },
  { name: "Tanzanian shilling", code: "TZS" },
  { name: "Ukrainian hryvnia", code: "UAH" },
  { name: "Ugandan shilling", code: "UGX" },
  { name: "United States dollar", code: "USD" },
  { name: "Uruguayan peso", code: "UYU" },
  { name: "Uzbekistani sum", code: "UZS" },
  { name: "Venezuelan digital bolívar", code: "VED" },
  { name: "Venezuelan sovereign bolívar", code: "VES" },
  { name: "Vietnamese đồng", code: "VND" },
  { name: "Vanuatu vatu", code: "VUV" },
  { name: "Samoan tālā", code: "WST" },
  { name: "Central African CFA franc", code: "XAF" },
  { name: "Eastern Caribbean dollar", code: "XCD" },
  { name: "West African CFA franc", code: "XOF" },
  { name: "CFP franc", code: "XPF" },
  { name: "Yemeni rial", code: "YER" },
  { name: "South African rand", code: "ZAR" },
  { name: "Zambian kwacha", code: "ZMW" }
];